import { gql } from '@apollo/client';
import { CompanyFields } from '../fragments';
import { CompanyDocumentsFields, CreditLineFields, MasterEntityBasicField } from './fragment';
import { UserIdentification } from '../user/fragments';

export const UPDATE_INVOICE_PROVIDER_CREDENTIALS = gql`
  mutation upload(
    $companyId: Int!
    $password: String!
    $naturalPersonRut: String!
    $credentialTypeId: Int!
    $secondUserName: String
  ) {
    uploadInvoiceProviderCredentials(
      companyId: $companyId
      password: $password
      naturalPersonRut: $naturalPersonRut
      credentialTypeId: $credentialTypeId
      secondUsername: $secondUserName
    ) {
      company {
        ...CompanyFields
      }
    }
  }
  ${CompanyFields}
`;

export const UPLOAD_SII_CREDENTIALS = gql`
  mutation UploadSiiCredentials($companyId: Int!, $siiPassword: String!) {
    uploadSiiCredentials(companyId: $companyId, siiPassword: $siiPassword) {
      success
      message
    }
  }
`;

export const ADD_COMPANY_PREFERENCES = gql`
  mutation addCompanyEmailPreference($companyId: Int!, $mail: String!) {
    addCompanyEmailPreference(companyId: $companyId, mail: $mail) {
      success
      message
    }
  }
`;

export const DELETE_COMPANY_PREFERENCES = gql`
  mutation eraseCompanyEmailPreference($companyId: Int!, $mail: String!) {
    eraseCompanyEmailPreference(companyId: $companyId, mail: $mail) {
      success
      message
    }
  }
`;

export const CREATE_BANK_ACCOUNT = gql`
  mutation CreateBankAccount(
    $accountEmail: String!
    $accountName: String!
    $accountNumber: String!
    $accountRut: String!
    $accountType: String!
    $bankName: String!
    $currency: String
  ) {
    createBankAccount(
      accountEmail: $accountEmail
      accountName: $accountName
      accountNumber: $accountNumber
      accountRut: $accountRut
      accountType: $accountType
      bankName: $bankName
      currency: $currency
    ) {
      bankAccountCreated {
        id
        accountEmail
        accountName
        accountNumber
        accountRut
        accountType
        bankName
        active
        currency {
          id
          isoCode
        }
      }
    }
  }
`;

export const EDIT_BANK_ACCOUNT = gql`
  mutation EditBankAccount(
    $id: ID!
    $accountEmail: String!
    $accountName: String!
    $accountNumber: String!
    $accountType: String!
    $bankName: String!
    $currency: String!
  ) {
    editBankAccount(
      id: $id
      accountEmail: $accountEmail
      accountName: $accountName
      accountNumber: $accountNumber
      accountType: $accountType
      bankName: $bankName
      currency: $currency
    ) {
      bankAccount {
        id
        accountEmail
        accountName
        accountNumber
        accountRut
        accountType
        bankName
        active
        currency {
          id
          isoCode
        }
      }
    }
  }
`;

export const BANK_ACCOUNT_ACTIVE_CHANGE = gql`
  mutation bankAccountActiveChange($bankId: Int!) {
    bankAccountActiveChange(bankId: $bankId) {
      bankAccount {
        id
        accountEmail
        accountName
        accountNumber
        accountRut
        accountType
        bankName
        active
        currency {
          id
          isoCode
        }
      }
    }
  }
`;

export const SET_NEW_PHONE = gql`
  mutation newPhone($newPhone: String!, $companyId: String!) {
    addCompanyPhoneNumber(newPhone: $newPhone, companyId: $companyId) {
      success
      message
    }
  }
`;

export const UPLOAD_COMPANY_DOCUMENT = gql`
  mutation UploadCompanyDocument(
    $companyId: ID!
    $file: Upload!
    $documentType: String!
    $year: Int
  ) {
    uploadCompanyDocument(
      companyId: $companyId
      file: $file
      documentType: $documentType
      year: $year
    ) {
      company {
        id
        documents {
          ...CompanyDocumentsFields
        }
      }
    }
  }
  ${CompanyDocumentsFields}
`;

export const UPLOAD_CERTIFICATE = gql`
  mutation UploadDigitalCertificate(
    $certificatePassword: String!
    $companyId: Int!
    $digitalCertificate: Upload!
    $credentialProviderName: String!
  ) {
    uploadDigitalCertificate(
      certificatePassword: $certificatePassword
      companyId: $companyId
      digitalCertificate: $digitalCertificate
      credentialProviderName: $credentialProviderName
    ) {
      digitalCertificate {
        id
      }
    }
  }
`;

export const GENERATE_PORTFOLIO_URL = gql`
  mutation GeneratePortfolioSummary(
    $companyId: Int!
    $product: String!
    $documentFormat: PortfolioFormatEnum!
    $portfolioType: PortfolioTypeEnum
    $startDate: Date
    $endDate: Date
    $currency: String
  ) {
    generatePortfolioSummary(
      companyId: $companyId
      product: $product
      documentFormat: $documentFormat
      portfolioType: $portfolioType
      startDate: $startDate
      endDate: $endDate
      currency: $currency
    ) {
      url
    }
  }
`;

export const REGISTER_COMPANY = gql`
  mutation RegisterCompany(
    $companyRut: String!
    $siiPassword: String!
    $isNotRegister: Boolean
  ) {
    registeCompany(
      companyRut: $companyRut
      siiPassword: $siiPassword
      isNotRegister: $isNotRegister
    ) {
      response
      socialReason
    }
  }
`;

export const UPLOAD_CREDENTIALS = gql`
  mutation uploadCredentials(
    $username: String!
    $password: String!
    $companyId: Int
    $credentialProvider: Int!
    $source: String
    $origin: String
  ) {
    uploadCredentials(
      username: $username
      password: $password
      companyId: $companyId
      credentialProvider: $credentialProvider
      source: $source
      origin: $origin
    ) {
      getUser {
        id
        selectedCompany {
          ...CompanyFields
        }
      }
    }
  }
  ${CompanyFields}
`;

export const REGISTER_MX_COMPANY = gql`
  mutation RegisterMxCompany($companyRfc: String!, $password: String!) {
    registerMxCompany(companyRfc: $companyRfc, password: $password) {
      response
      socialReason
    }
  }
`;

export const UPDATE_LOKAL_DEBTOR = gql`
  mutation NotifyLokalCredential($nationalIdentifier: String!) {
    notifyLokalCredentials(nationalIdentifier: $nationalIdentifier) {
      success
    }
  }
`;

export const ADD_COMPANY_TO_SUPER_USER = gql`
  mutation addCompany($companyRut: String!) {
    addCompanyToSuperuser(companyRut: $companyRut) {
      user {
        id
        selectedCompany {
          ...CompanyFields
        }
      }
    }
  }
  ${CompanyFields}
`;

export const ADD_OR_EDIT_CONTACT = gql`
  mutation createOrEditContact(
    $isNew: Boolean!
    $id: Int
    $name: String
    $phoneNumber: String
    $position: String
    $email: String!
    $company: Int!
    $contactType: String!
    $source: String
  ) {
    createOrEditContact(
      isNew: $isNew
      name: $name
      phoneNumber: $phoneNumber
      position: $position
      email: $email
      id: $id
      company: $company
      contactType: $contactType
      source: $source
    ) {
      messageType
      message
      contact {
        id
        name
        email
        position
        phoneNumber
        userInstance {
          id
          activeInvitations {
            companyFrom {
              id
              rut
            }
          }
        }
      }
    }
  }
`;

export const UPLOAD_CONTACTS_EXCEL = gql`
  mutation uploadContactsExcel($inputUploadFiles: Upload!, $companyId: Int!) {
    uploadContactsExcel(
      inputUploadFiles: $inputUploadFiles
      companyId: $companyId
    ) {
      message
      messageType
    }
  }
`;
export const ERASE_CONTACT = gql`
  mutation eraseContact($id: Int!, $company: Int!, $contactType: String) {
    eraseContact(id: $id, company: $company, contactType: $contactType) {
      messageType
      message
    }
  }
`;

export const ACCEPT_TERMS = gql`
  mutation AcceptTermsAndConditions($companyId: Int!, $product: String) {
    acceptTermsAndConditions(companyId: $companyId, product: $product) {
      user {
        id
        selectedCompany {
          id
          hasLendingAcceptedterms @client
          hasCollectionAcceptedTerms @client
          currentUserAcceptedTerms {
            id
            product
          }
        }
      }
    }
  }
`;

export const ADD_COLLECTION_ACTION = gql`
  mutation AddCollectionAction(
    $collectionManagerIds: [Int]!
    $actionType: CollectionActionEnum!
    $comment: String
    $collectionContactIds: [Int]!
  ) {
    addCollectionAction(
      collectionAction: {
        collectionManagerIds: $collectionManagerIds
        actionType: $actionType
        comment: $comment
        collectionContactIds: $collectionContactIds
      }
    ) {
      collectionManagers {
        id
        collector {
          ...UserIdentification
        }
        collectionPriority {
          value
        }
        actions {
          id
          comment
          createdAt
          actionType
          contacts {
            id
            name
            position
            email
            phoneNumber
          }
          author {
            ...UserIdentification
          }
        }
      }
    }
  }
  ${UserIdentification}
`;

export const COLLECT = gql`
  mutation Collect($collectionManagerIds: [Int]!, $paymentDatetime: DateTime!) {
    collect(
      collectionManagerIds: $collectionManagerIds
      paymentDatetime: $paymentDatetime
    ) {
      collectionManagers {
        id
        collector {
          ...UserIdentification
        }
        collectionPriority {
          value
        }
        actions {
          id
          comment
          createdAt
          actionType
          contacts {
            id
            name
            position
            email
            phoneNumber
          }
          author {
            ...UserIdentification
          }
        }
      }
    }
  }
  ${UserIdentification}
`;

export const CREATE_CREDIT_LINE = gql`
  mutation CreateCreditLine($masterEntityId: String!, $currency: String!, $limit: Float!) {
    createCreditLine(masterEntityId: $masterEntityId, currency: $currency, limit: $limit) {
      masterEntity {
        ...MasterEntityBasicField
        creditlines {
          ...CreditLineFields
        }
      }
    }
  }
  ${CreditLineFields}
  ${MasterEntityBasicField}
`;

export const EDIT_CREDIT_LINE = gql`
  mutation EditCreditLine($creditLineId: String!, $newLimit: Float!) {
    editCreditLine(creditLineId: $creditLineId, newLimit: $newLimit) {
      masterEntity {
        ...MasterEntityBasicField
        creditlines {
          ...CreditLineFields
        }
      }
    }
  }
  ${CreditLineFields}
  ${MasterEntityBasicField}
`;

export const RESOLVE_COMPANY_EVALUATION_RESTRICTIONS = gql`
  mutation resolveCompanyEvaluationRestrictions(
    $restrictions: [CompanyEvaluationRestrictionsInputType]!
  ) {
    resolveCompanyEvaluationRestrictions(restrictions: $restrictions) {
      companyEvaluationRestrictions {
        id
      }
    }
  }
`;

export const CHANGE_COMPANY_EVALUATION_RESTRICTION_STATUS = gql`
  mutation changeCompanyEvaluationRestrictionStatus(
    $restriction: Int!
    $action: String!
  ) {
    changeCompanyEvaluationRestrictionStatus(
      restriction: $restriction
      action: $action
    ) {
      companyEvaluationRestriction {
        id
      }
    }
  }
`;

export const UPDATE_BUREAUS = gql`
  mutation updateBureaus(
    $ids: [String]!
    $updateDicom: Boolean!
    $updateBusinessProfile: Boolean!
    $updateCurrentBehaviour: Boolean!
    $delay: Boolean!
  ) {
    updateBureaus(
      ids: $ids
      updateDicom: $updateDicom
      updateBusinessProfile: $updateBusinessProfile
      updateCurrentBehaviour: $updateCurrentBehaviour
      delay: $delay
    ) {
      success
    }
  }
`;

export const FETCH_PRESIGNED_URL = gql`
  mutation fetchPresignedUrl($globalId: String!, $fileAttributeLabel: String) {
    fetchPresignedUrl(globalId: $globalId, fileAttributeLabel: $fileAttributeLabel) {
      url
    }
  }
`;

export const CREATE_COMPANY_RESTRICTION = gql`
  mutation createCompanyRestriction(
    $requestRestriction: [CompanyRestrictionInputType]
  ) {
    createCompanyRestriction(requestRestriction: $requestRestriction) {
      success
      message
    }
  }
`;

export const CREATE_MASTER_ENTITY = gql`
  mutation createMasterEntity($rutWithDv: String!) {
    createMasterEntity(rutWithDv: $rutWithDv) {
      masterEntity {
        id
      }
    }
  }
`;

export const GENERATE_COMPANY_FRAMEWORK_CONTRACT = gql`
  mutation generateCompanyFrameworkContract(
    $companyId: String!
    $contractInfo: CompanyContractInfoInput!
    $legalRepresentatives: [CompanyLegalRepresentativesInput]
    $endorsements: [CompanyEndorsementsInput]
  ) {
    generateCompanyFrameworkContract(
      companyId: $companyId
      contractInfo: $contractInfo
      legalRepresentatives: $legalRepresentatives
      endorsements: $endorsements
    ) {
      frameworkContract {
        id
        globalAppId
        createdAt
        lastFile
      }
    }
  }
`;
